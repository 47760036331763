module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ws-on-demand","short_name":"wsod","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/clients/workco/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"202869c0b732cf83a6cb7aa1931ca74c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"na","defer":true,"head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://e155950eef744825926bdf260885eb16@o313230.ingest.sentry.io/5832029","release":"56cd4874e6394c7d11bfb4257d4756cf80133cc7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
